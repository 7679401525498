<template>
    <div>
      <ui-h color="black" small>Welcome to DiggDawg</ui-h>
      <ui-p color="black">You will receive an email sent to {{user.email}} as proof of purchase.</ui-p>
      <ui-p color="black">Your purchase has been successful and you can now begin using the DiggDawg app. For Android users search the play store and for iOS users search the apple store for 'DiggDawg'. </ui-p>
      <ui-p color="black">If you need to get in touch select 'help and information' or reach out on social media.</ui-p>
    </div>
</template>

<script>
export default {
  computed: {
      isAuthenticated() {
        return this.$store.getters.getterIsAuthenticated
      },

      user() {
        return this.$store.getters.getterUser
      }
    },
}
</script>
